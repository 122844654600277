import "./wedding-info.css";

export default function WeddingInfo() {
  return (
    <div className="wedding-info">
      <div className="location">
        <p>
          <b>Recepción</b>
        </p>
        <p>13:30</p>
        <p>
          <a
            href="https://maps.app.goo.gl/QDuAokq9GDjP3CNU9"
            target="_blank"
            rel="noreferrer"
          >
            Finca Botania
          </a>
        </p>
        <p>Málaga</p>
      </div>
      <div className="divider"></div>
      <div className="buses">
        <p>
          <b>Autobús</b>
        </p>
        <p>Vuelta: por determinar</p>
        {/* <p>Calle Victoria 25</p> */}
      </div>
    </div>
  );
}

import "./date-banner.css";

export default function DateBanner() {
  return (
    <div className="date-banner">
      <p className="weekday">Sábado</p>
      <div className="full-date">
        <p>Abril</p>
        <p>27</p>
        <p>2024</p>
      </div>
      <p className="time">13:30</p>
    </div>
  );
}

import logo from "./logo.svg";
import "./App.css";
import NavBar from "./components/nav-bar/nav-bar.js";
import Letter from "./components/letter/letter.js";
import DateBanner from "./components/date-banner/date-banner.js";
import WeddingInfo from "./components/wedding-info/wedding-info.js";
import RSVP from "./components/rsvp/rsvp";
import Info from "./components/info/info.js";
import Footer from "./components/footer/footer.js";
import { Widget } from "@typeform/embed-react";

function App() {
  return (
    <div className="App">
      {/* <NavBar></NavBar> */}
      <main>
        <div
          className="hero-section"
          style={{ backgroundImage: "url(/nieves_y_ruben.jpg)" }}
        >
          <div className="cover">
            <h1 class="styled-text" style={{ marginBottom: "0px" }}>
              Nieves
            </h1>
            <h1
              class="styled-text"
              style={{ margin: "0px", lineHeight: "2rem" }}
            >
              y
            </h1>
            <h1 class="styled-text" style={{ marginTop: "3rem" }}>
              Ruben
            </h1>
          </div>
        </div>
        <section class="centered spacing">
          <p style={{ lineHeight: "2rem" }}>
            Qué suerte poder juntarnos con la gente a la que queremos para
            celebrar la vida. Nos casamos y nos encantaría que estuvieras con
            nosotros.
          </p>
          <DateBanner></DateBanner>
          <WeddingInfo></WeddingInfo>
          <RSVP></RSVP>
          <h2 className="styled-text wishes">/Te esperamos!</h2>
          <img
            className="frame-left"
            src="/frame_left.png"
            alt="hojas verdes"
          />
          <img
            className="frame-right"
            src="/frame_right.png"
            alt="hojas verdes"
          />
          <img
            className="section-divider"
            src="/divider.png"
            alt="linea de sección"
          />
        </section>
        <section
          id="rsvp"
          className="spacing"
          style={{ paddingBottom: "4rem" }}
        >
          <h2>Formulario de asistencia</h2>
          <Widget
            id="unzPGasD"
            className="typeform"
            style={{
              backgroundColor: "##F5F5F5",
            }}
          />
        </section>
      </main>
    </div>
  );
}

export default App;

import "./rsvp.css";

export default function RSVP() {
  return (
    <div className="rsvp">
      <div className="liner"></div>
      <a className="confirmation" href="#rsvp">
        <p>Confirmar Asistencia</p>
      </a>
      <div className="liner2"></div>
    </div>
  );
}
